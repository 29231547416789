<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <!-- DEPARTMENTS LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4 class>Departments</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="
                flex flex-wrap
               
                justify-between
                ag-grid-table-actions-right
              "
            >
              <vs-input
                class="mb-4 md:mb-0 mr-0 sm:mr-4 w-full sm:w-auto"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <!-- <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
              >Export as CSV</vs-button>
              </div>-->
              <vx-tooltip text="Export Excel" class="w-full sm:w-auto">
                <!-- :href="excelurl+`/v2/department/excel/download?${}&search=${searchQuery}`" -->
                <a
                  :href="
                    excelurl +
                      `/v2/department/excel/download?organization_id=${organization_id}&token=${temp_token}&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive != 'archive'"
                >
                  <vs-button class="px-4 w-full sm:w-auto">
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>

              <vx-tooltip text="Export Archive Excel" class="w-full sm:w-auto">
                <a
                  :href="
                    excelurl +
                      `/v2/department/excel/download?organization_id=${organization_id}&token=${temp_token}&archive=1&search=${searchQuery}`
                  "
                  target="_blank"
                  v-if="this.archive == 'archive'"
                >
                  <vs-button class="px-4 w-full sm:w-auto">
                    <span class="d-inline-flex pr-5 text-white"
                      >archivedDepartmentExcelDownload</span
                    >

                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </vx-tooltip>
            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowClicked="onRowDataChanged"
          :rowData="departments"
          rowSelection="multiple"
          :animateRows="true"
          :floatingFilter="false"
          :paginationPageSize="paginationPageSize"
          :pagination="true"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "@/views/ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
const VxTour = () => import("@/components/VxTour.vue");
import departmentService from "@/services/departmentService.js";
import SearchService from "@/services/searchService.js";
import DepartmentStatus from "@/views/apps/masters/department/DepartmentStatus";
import DepartmentDetails from "@/views/apps/masters/department/DepartmentDetails.vue";
import EditDepartment from "@/views/apps/masters/department/EditDepartment.vue";
import DepartmentArchives from "@/views/apps/masters/department/DepartmentArchives.vue";
import { BASEURL } from "@/config/index.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
    "switch-component": {
      template: `<span>KOZO</span>`,
    },
  },
  props: ["listType"],
  data() {
    return {
      excelurl: BASEURL,
      organization_id: "",
      totalPages: 0,
      overlayLoadingTemplate: null,
      archive: this.listType,
      departments: [],
      User: "Select User",
      switchValue: true,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" },
      ],
      contacts: contacts,
      columnDefs: [],
    };
  },
  beforeMount() {
    eventBus.$on("refreshTabledepa", (e) => {
      this.getDepartmentList(this.currentPage);
    });
    this.organization_id = localStorage.getItem("OrganizationID");
    this.columnDefs = [
      // {
      //   headerName: "Sr.No.",
      //   field: "firstname",
      //   width: 150,
      //   filter: true,
      //   checkboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   headerCheckboxSelection: true
      // },
      {
        headerName: "Name",
        field: "department_name",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Company",
        field: "company_name",
        filter: false,
        width: 255,
      },
      // {
      //   headerName: "Description",
      //   field: "description",
      //   filter: false,
      //   width: 270,
      // },
      {
        headerName: "Status",
        field: "department_active",
        filter: false,
        width: 95,
        sortable: false,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: DepartmentStatus,
      },
      {
        headerName: "Details",
        field: "department_id",
        filter: false,
        width: 100,
        sortable: false,
        cellRendererFramework: DepartmentDetails,
      },
      {
        headerName: "Edit",
        field: "",
        filter: false,
        width: 80,
        sortable: false,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: EditDepartment,
      },
      {
        headerName: this.archive == "archive" ? "Unarchive" : "Archive",
        field: this.archive ? "1" : "4",
        filter: false,
        sortable: false,
        width: this.archive ? 140 : 104,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: DepartmentArchives,
      },
    ];
    // this.setColums();
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getDepartmentList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    // setColums: function() {

    // },
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function() {
      console.log("object");
    },
    updateSearchQuery(val) {
      console.log(this.archive);
      let bol = this.archive == "archive" ? true : false;
      SearchService.getAllSerchResultV2(val, "department", null, bol)
        .then((response) => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.departments = data.data;
          } else {
            this.departments = [];
            // this.$vs.notify({
            //   title: "Search data not found",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning"
            // });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getDepartmentList: function(currentPage) {
      let filter = {
        page: currentPage,
      };
      if (this.archive == "archive") {
        filter["archive"] = true;
      }
      departmentService
        .getAllDepartments(filter)
        .then((response) => {
          const { data } = response;
          if (data.err || data.Status == false) {
          } else {
            this.departments = data.data;
            this.totalPages = data.pagination.last_page;
            this.gridApi.sizeColumnsToFit();
            // if (this.archive == "archive") {
            //   this.departments = data.data.filter(
            //     ({ department_active }) => department_active === "4",
            //   );
            // } else {
            //   this.departments = data.data.filter(x => {
            //     return (
            //       x.department_active === "1" ||
            //       x.department_active === "2"
            //     );
            //   });
            // }
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
  },
  mounted() {
    if (this.checkPermission(["department", "GET"])) {
      this.getDepartmentList(this.currentPage);
    }
    this.gridApi = this.gridOptions.api;
  },
};
</script>
